import { Icon, Loading, Thumbnail } from 'components';
import React from 'react';
import { CATEGORIES, FetchActions, MAIN_CATEGORY } from 'types';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from 'routes';
import { previewImage } from '@mediafellows/tuco/dist/lib/helpers';
import { fetchFeaturedData } from 'utils/api/fetch-featured-data';
import useSWR from 'swr';
import { useSessionStore } from 'store/session-store/session-store';

type TvMoviesContentProps = {
  type: 'tv' | 'movies';
};

export const TvMoviesContent = ({ type }: TvMoviesContentProps) => {
  const history = useHistory();
  const initialLoadDone = useSessionStore((state) => state.initialLoadDone);

  const categories = CATEGORIES[type];
  const isMovies = type === MAIN_CATEGORY.MOVIES;
  const label = isMovies ? 'movies' : 'television';
  const mainRoute = isMovies ? Routes.MOVIES : Routes.TV;

  const { data: featuredTV, isLoading: isLoadingFeaturedTV } = useSWR(
    initialLoadDone && !isMovies ? FetchActions.FEATURED_TV : null,
    () => fetchFeaturedData('tv'),
  );

  const { data: featuredMovies, isLoading: isLoadingFeaturedMovies } = useSWR(
    initialLoadDone && isMovies ? FetchActions.FEATURED_MOVIES : null,
    () => fetchFeaturedData('movies'),
  );

  const isLoading = isMovies ? isLoadingFeaturedMovies : isLoadingFeaturedTV;
  const featured = isMovies ? featuredMovies : featuredTV;
  const filteredFeatured = featured?.slice(0, 2);

  const handleClick = (e) => {
    const id = e.currentTarget.id;
    history.push(`${Routes.PRODUCT}/${id}`);
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <div className="d-inline-block pointer text-primary d-block d-sm-none" data-bs-dismiss="offcanvas">
          <Icon name="x-lg" />
        </div>

        <div
          className="d-inline-block pointer text-primary d-none d-sm-block offcanvas_close_button_align"
          data-bs-dismiss="offcanvas"
        >
          <Icon name="x-lg" />
        </div>
      </div>

      <div className="row row-cols-1 row-cols-lg-2">
        <div className="col">
          <h3 className="mb-5">Browse {label} by </h3>
          <hr />
          <div className="row mt-5">
            <div className="col-12 col-md-6 mb-6">
              <Link to={`${mainRoute}/all`} className="h4 header__link-offcanvas">
                All Genres
              </Link>
            </div>
            {categories.map(({ frontend }, i) => {
              return (
                <div className="col-12 col-md-6 mb-6" key={i}>
                  <Link to={`${mainRoute}/${frontend}`} className="text-capitalize h4 header__link-offcanvas">
                    {frontend?.includes('unscripted') ? 'Alternative' : frontend}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>

        <div className="col d-none d-sm-block">
          <div className="d-flex align-items-center justify-content-between mb-5">
            <h3 className="mb-0">Recommended </h3>
          </div>
          <div className="row">
            {isLoading && (
              <div className="col-12">
                <Loading />
              </div>
            )}
            {!isLoading &&
              filteredFeatured?.map((product) => {
                const { id, default_layer, title } = product;
                const { year_of_production, rating } = default_layer || {};

                return (
                  <div className="col-12 col-lg-6 offcanvas_recommended" id={id} key={id} onClick={handleClick}>
                    <Thumbnail
                      image={previewImage(product)?.url}
                      text={{
                        title,
                        content: [year_of_production, rating],
                      }}
                      showOverlay={false}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
