import {
  Actions,
  ApplyFiltersOptions,
  DefaultFilterRecord,
  DefaultFilters,
  FILM_TYPES,
  FilterArray,
  FilterStore,
  FilterValue,
  TV_TYPES,
} from 'types';

export const updateSearchFilter = ({
  filterValue,
  defaultFilters,
}: {
  filterValue: FilterValue | FilterArray;
  defaultFilters: DefaultFilters;
}): DefaultFilters => {
  if (filterValue !== defaultFilters?.q?.value) {
    return {
      q: {
        value: filterValue,
        payload: ['+main', 'q', filterValue],
      },
    };
  }

  return {
    q: {
      ...(defaultFilters?.q as DefaultFilterRecord),
    },
  };
};

export const applyFilters = (options: ApplyFiltersOptions) => {
  const { values = ['', ''], action, store: useStore } = options;
  const store = useStore.getState() as FilterStore;
  const filters = store.filters;
  const defaultFilters = store.defaultFilters;
  const activeFilters = store.activeFilters;

  // reset filters
  if (action === Actions.RESET_ALL || action === Actions.RESET) {
    const filtersWithoutSearch = { ...defaultFilters };

    // exclude q from filter reset
    if (action !== Actions.RESET_ALL && typeof filtersWithoutSearch.q !== 'undefined') {
      delete filtersWithoutSearch.q;
    }

    useStore.setState((state) => ({
      filters: { ...state.filters, ...filtersWithoutSearch },
      page: 1,
      activeFilters: [],
    }));
    return;
  }

  const [filterName, filterValue] = values;

  const { category_ids, year_of_production, duration, q, product_type } = filters;
  let updatedFilters: DefaultFilters = { ...filters };
  let updatedActiveFilters: string[] = [...activeFilters];

  // genres(category_ids) filters
  if (category_ids && filterName === 'category_ids') {
    const isDefault = (filterValue as FilterArray).every(
      (val, index) => val === (defaultFilters?.category_ids?.value as FilterArray)[index],
    );

    if (!isDefault) {
      updatedFilters = {
        ...updatedFilters,
        category_ids: {
          value: filterValue,
          payload: ['default_layer.meta.category_ids', 'in', filterValue],
        },
      };
      if (!updatedActiveFilters.includes('category_ids')) {
        updatedActiveFilters.push('category_ids');
      }
    } else {
      updatedFilters = {
        ...updatedFilters,
        category_ids: {
          ...(defaultFilters?.category_ids as DefaultFilterRecord),
        },
      };
      updatedActiveFilters = updatedActiveFilters.filter((item) => item !== 'category_ids');
    }
  }

  // year_of_production filters
  if (year_of_production && filterName === 'year_of_production') {
    const isDefault = (filterValue as FilterArray).every(
      (val, index) => val === (defaultFilters?.year_of_production?.value as FilterArray)[index],
    );

    if (isDefault) {
      updatedFilters = {
        ...updatedFilters,
        year_of_production: {
          ...(defaultFilters?.year_of_production as DefaultFilterRecord),
        },
      };

      updatedActiveFilters = updatedActiveFilters.filter((item) => item !== 'year_of_production');
    } else {
      updatedFilters = {
        ...updatedFilters,
        year_of_production: {
          value: filterValue,
          payload: [
            'default_layer.meta.year_of_production',
            'range',
            (filterValue as FilterArray)[0],
            (filterValue as FilterArray)[1],
          ],
        },
      };

      if (!updatedActiveFilters.includes('year_of_production')) {
        updatedActiveFilters.push('year_of_production');
      }
    }
  }

  // duration filters
  if (duration && filterName === 'duration') {
    const isDefault = (filterValue as FilterArray).every(
      (val, index) => val === (defaultFilters?.duration?.value as FilterArray)[index],
    );

    if (isDefault) {
      updatedFilters = {
        ...updatedFilters,
        duration: {
          ...(defaultFilters?.duration as DefaultFilterRecord),
        },
      };

      updatedActiveFilters = updatedActiveFilters.filter((item) => item !== 'duration');
    } else {
      updatedFilters = {
        ...updatedFilters,
        duration: {
          value: filterValue,
          payload: [
            'default_layer.meta.duration',
            'range',
            (filterValue as FilterArray)[0],
            (filterValue as FilterArray)[1],
          ],
        },
      };
      if (!updatedActiveFilters.includes('duration')) {
        updatedActiveFilters.push('duration');
      }
    }
  }

  // search(q) filters
  if (q && filterName === 'q') {
    updatedFilters = { ...updatedFilters, ...updateSearchFilter({ filterValue, defaultFilters }) };
  }

  // categories(product) filters
  if (product_type && filterName === 'product_type') {
    if (!filterValue) {
      updatedFilters = {
        ...updatedFilters,
        product_type: {
          ...(defaultFilters?.product_type as DefaultFilterRecord),
        },
        category_ids: {
          ...(defaultFilters?.category_ids as DefaultFilterRecord),
        },
      };
      updatedActiveFilters = updatedActiveFilters.filter((item) => item !== 'product_type');
    } else {
      updatedFilters = {
        ...updatedFilters,
        product_type: {
          value: filterValue,
          payload: ['default_layer.meta.product_type', 'in', filterValue === 'tv' ? TV_TYPES : FILM_TYPES],
        },
        category_ids: {
          ...(defaultFilters?.category_ids as DefaultFilterRecord),
        },
      };

      if (!updatedActiveFilters.includes('product_type')) {
        updatedActiveFilters.push('product_type');
      }
    }
  }

  useStore.setState((state) => ({
    ...state,
    page: 1,
    filters: { ...updatedFilters },
    activeFilters: [...updatedActiveFilters],
  }));
};
