import React, { useEffect, useState } from 'react';
import { deepFind, getType } from 'utils';
import { get, isEmpty, each } from 'lodash';
import { Link } from 'react-router-dom';
import { Routes } from 'routes';
import { CATEGORIES, Product3Types } from '../../types';
import { fetchSeasons } from 'utils/api/fetch-seasons';
import useSWR from 'swr';

const ProductBreadcrumbs = ({ product, categories }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const type = getType(product?.default_layer?.meta.product_type);
  const route = type === 'Television' ? Routes.TV : Routes.MOVIES;
  const isEpisode = product.type === Product3Types.EPISODE;
  const isSeason = product.type === Product3Types.SEASON;
  const { ancestry, id } = product;

  const rootId = ancestry ? Number(ancestry.split('/')[0]) : id;

  const { data } = useSWR(['seasons-test-123', rootId], ([, rootId]) => fetchSeasons(rootId));
  let currentSeason = null;

  const { rootProduct, descendents = [] } = data || {};

  const seasons = descendents.filter((item) => item.type === Product3Types.SEASON);

  if (isSeason) {
    currentSeason = product;
  }

  if (isEpisode) {
    currentSeason = seasons?.find((season) => season.id === product.parent_id);
  }

  useEffect(() => {
    const breadcrumbs = [];

    const productCategoryIds = get(product, 'default_layer.meta.category_ids');
    const productCategory = !isEmpty(productCategoryIds) ? deepFind(categories, productCategoryIds[0]) : null;
    const parentCategory = deepFind(categories, productCategory?.parent_id);

    if (productCategoryIds) {
      each(productCategoryIds, (id) => {
        const category = deepFind(categories, id);
        if (category) breadcrumbs.push({ name: category.name, id: category.id });
      });
    }

    if (parentCategory && !breadcrumbs.some((item) => item.name === parentCategory.name)) {
      breadcrumbs.unshift({ name: parentCategory.name, id: parentCategory.id });
    }

    setBreadcrumbs(breadcrumbs);
  }, [categories, product]);

  const tvCategoryNames = CATEGORIES.tv.map((c) => c.frontend);
  const movieCategoryNames = CATEGORIES.movies.map((c) => c.frontend);

  const filteredBreadcrumbs =
    type.toLowerCase() === 'television'
      ? (breadcrumbs || []).filter((item: any) => tvCategoryNames.includes(item?.name?.toLowerCase() || ''))
      : type.toLowerCase() === 'movie'
      ? (breadcrumbs || []).filter((item: any) => movieCategoryNames.includes(item?.name?.toLowerCase() || ''))
      : [];

  return (
    <div className="product-breadcrumbs w-100 col-12 d-flex align-items-center mb-3">
      {type && (
        <>
          <Link className="text-decoration-none" to={route}>
            {type}
          </Link>
        </>
      )}

      {filteredBreadcrumbs.map((item: any, index) => {
        return (
          <React.Fragment key={index}>
            <div>&nbsp;/&nbsp;</div>
            <Link
              className="product-breadcrumb text-decoration-none"
              to={`${route}/${item.name.toLowerCase()}`}
              key={index}
            >
              {item?.name?.includes('Unscripted') ? 'Alternative' : item?.name}
            </Link>
          </React.Fragment>
        );
      })}

      {isSeason && (
        <>
          <div>&nbsp;/&nbsp;</div>
          <Link className="product-breadcrumb text-decoration-none" to={`${Routes.PRODUCT}/${rootProduct?.id}`}>
            {rootProduct?.display_title}
          </Link>
        </>
      )}

      {isEpisode && (
        <>
          <div>&nbsp;/&nbsp;</div>
          <Link className="product-breadcrumb text-decoration-none" to={`${Routes.PRODUCT}/${rootProduct?.id}`}>
            {rootProduct?.display_title}
          </Link>
          <div>&nbsp;/&nbsp;</div>
          <Link className="product-breadcrumb text-decoration-none" to={`${Routes.PRODUCT}/${currentSeason?.id}`}>
            {currentSeason?.display_title}
          </Link>
        </>
      )}
    </div>
  );
};

export default ProductBreadcrumbs;
